import React from "react"
import ArticleContentBody from "../../components/pages/ArticleDetails/ArticleContentBody"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const SecurityAndAuthenticationSettings = () => {
  return (
    <Layout>
      <Seo
        title="Security And Authentication Settings"
        description={`Secure your Synkli account with our comprehensive guide to Security and Authentication settings. Learn to manage passwords, set up Two-Factor Authentication (2FA), and ensure account security.`}
      />

      {/* This slug is matched with articles in Content */}
      <ArticleContentBody slug={`security-and-authentication-settings`} />
    </Layout>
  )
}

export default SecurityAndAuthenticationSettings
